import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/modules/auth';
import { onboard } from '@/store/modules/onboard';
import { deal } from '@/store/modules/deal';
import { charity } from '@/store/modules/charity';
import { business } from '@/store/modules/business';
import { market } from '@/store/modules/market';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    onboard,
    deal,
    charity,
    business,
    market
  }
})
