import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import url from "@/service/url.js";
import {AuthError, NetworkError} from "@/errors/index.js";

const apiClient = axios.create({
  baseURL: url.get_https(),
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const refreshAuthLogic = failedRequest => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  // for when an accessToken exsists without a refreshToken
  if (accessToken !== null && refreshToken === null) {
    //window.location = '/login?r=security'
    return Promise.reject(new AuthError('No refresh token.'))
  }

  if (refreshToken !== null) {
    return apiClient.post('/token/refresh', { refreshToken })
      .then(tokenRefreshResponse => {
        localStorage.setItem('accessToken', tokenRefreshResponse.data.accessToken)
        localStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken)
        failedRequest.response.config.headers.Authorization = 'Bearer ' + tokenRefreshResponse.data.accessToken

        return Promise.resolve()
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.error(err.response)
          return Promise.reject(new AuthError(err.response.data.message))
        } else if (err.request) {
          // client never received a response, or request never left
          console.error(err.request)
          return Promise.reject(new NetworkError('An unknown network error occurred.'))
        }
        console.error(err)
        return Promise.reject(err);
      })
  }
}

createAuthRefreshInterceptor(apiClient, refreshAuthLogic)

export default {
  signup(params) {
    return apiClient.post("/signup", params);
  },
  login(params) {
    return apiClient.post("/login", params);
  },
  logout(params) {
    return refresh_token(apiClient).post("/logout", params);
  },
  forgotPassword(params) {
    return apiClient.post("/send_password_reset", params);
  },
  resetPassword(params) {
    return apiClient.post("/reset_password_and_login", params);
  },
  confirmEmail(params) {
    return apiClient.post("/confirm_email", params);
  },

  updateUser(slug, params) {
    return refresh_token(apiClient).post("/user/" + slug, params);
  },
  deleteUser(user_slug) {
    return refresh_token(apiClient).delete("/user/" + user_slug)
  },

  findUserByEmail (searchterm) {
    return refresh_token(apiClient).post('/user/search-by/email/' + searchterm)
  },
  addUserToOrganization (org_slug, user_slug, role) {
    return refresh_token(apiClient).post(`/organization/${org_slug}/add-user/${user_slug}/as/${role}`)
  },
  updateOrganizationUserRole (org_user_id, role) {
    return refresh_token(apiClient).post(`/organization-user/${org_user_id}/as/${role}`)
  },
  removeUserFromOrganization (org_user_id) {
    return refresh_token(apiClient).delete(`/organization-user/${org_user_id}`)
  },

  getBusiness(params) {
    return refresh_token(apiClient).get("/user/" + params.user_slug + "/organization/" + params.org_slug);
  },
  createBusiness(user_slug, params) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/", params);
  },
  updateBusiness(user_slug, slug, params) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/" + slug, params);
  },

  removeCreditCard(user_slug, slug) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/" + slug + "/remove_card")
  },

  createDeal(user_slug, org_slug, params) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/" + org_slug + "/deal/", params);
  },
  updateDeal(user_slug, org_slug, slug, params) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/" + org_slug + "/deal/" + slug, params);
  },
  clickClaimDeal(params) {
    return refresh_token(apiClient).post("/deal/claim", params);
  },
  scanClaimDeal(params) {
    return refresh_token(apiClient).post("/deal/" + params.deal_slug + "/claim", params);
  },
  getDeals(path) {
    const user = JSON.parse(localStorage.getItem("currentUser"))
    if (user !== null && user["slug"] != undefined) {
      return refresh_token(apiClient).get("/deals/" + path);
    } else {
      return apiClient.get("/deals/" + path);
    }
  },
  getDeal(path) {
     //TODO - use single refresh_token
    const user = JSON.parse(localStorage.getItem("currentUser"))
    if (user !== null && user["slug"] != undefined) {
      return refresh_token(apiClient).post("/dealuser/" + path, {user_slug: user["slug"]});
    } else {
      return apiClient.get("/deal/" + path);
    }
  },

  getDealByCode(dealCode) {
    //TODO - use single refresh_token
    const user = JSON.parse(localStorage.getItem("currentUser"))
    const path = `/deal_code/${dealCode}`
    if (user !== null && user["slug"] != undefined) {
      return refresh_token(apiClient).get(path)
    } else {
      return apiClient.get(path)
    }
  },

  getDestinations() {
    return apiClient.get("/destinations");
  },

  getCharities() {
    return apiClient.get("/charities");
  },
  getCharity(slug) {
    return apiClient.get("/charities/" + slug);
  },
  donateCharity(params) {
    return refresh_token(apiClient).post("/user/" + params.user_slug + "/donate/" + params.charity_slug, params);
  },
  getAdminCharity(slug) {
    return refresh_token(apiClient).get("/admin/charities/" + slug);
  },
  createCharity(params) {
    return refresh_token(apiClient).post("/admin/charities/create", params);
  },
  updateCharity(slug, params) {
    return refresh_token(apiClient).patch("/admin/charities/" + slug, params);
  },

  getDmiMarkets() {
    return refresh_token(apiClient).get("/dmi_markets");
  },
  getDmiMarket(id) {
    return refresh_token(apiClient).get("/dmi_markets/" + id);
  },
  getDmiMarketByDmiId(dmi_id) {
    return refresh_token(apiClient).get("/dmi_markets/dmi_id/" + dmi_id);
  },
  createDmiMarket(user_slug, market) {
    return refresh_token(apiClient).post("/admin/" + user_slug + "/dmi_markets/create", market);
  },

  getBusinessTransactions(user_slug, org_slug, status) {
    return refresh_token(apiClient).get("/user/" + user_slug + "/organization/" + org_slug + "/transactions/" + status);
  },
  updateBusinessTransactionStatus(user_slug, org_slug, id, params) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/organization/" + org_slug + "/transaction/" + id, params);
  },

  getAdminBusinesses(user_slug) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/organizations");
  },
  getAdminUsers(user_slug) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/users");
  },
  getAdminTransactions(user_slug) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/transactions");
  },

  getAdminPayouts(user_slug, filter) {
    let path = "payouts"
    if (filter !== "") path += "/" + filter;
    return refresh_token(apiClient).get("/admin/" + user_slug + "/" + path);
  },
  getAdminPaypalBatches(user_slug) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/paypal_batches");
  },
  getAdminStats(user_slug) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/stats");
  },
  payoutsGenerate(user_slug, params) {
    return refresh_token(apiClient).post("/admin/" + user_slug + "/payouts/generate", params);
  },
  payoutsCancelUnclaimed(user_slug) {
    return refresh_token(apiClient).post("/admin/" + user_slug + "/payouts/cancel_unclaimed");
  },
  paypalBatchCreate(user_slug, params) {
    return refresh_token(apiClient).post("/admin/" + user_slug + "/paypal_batches/create", params);
  },
  paypalBatchSubmit(user_slug, params) {
    return refresh_token(apiClient).patch("/admin/" + user_slug + "/paypal_batches/" + params.id + "/submit", params);
  },
  paypalBatchRefresh(user_slug, params) {
    return refresh_token(apiClient).patch("/admin/" + user_slug + "/paypal_batches/" + params.id + "/update_from_paypal", params);
  },

  getTransactions(user_slug) {
    return refresh_token(apiClient).get("/user/" + user_slug + "/transactions")
  },

  getPayoutsBalance(user_slug) {
    return refresh_token(apiClient).get("/user/" + user_slug + "/payouts/balance");
  },
  getPayouts(user_slug) {
    return refresh_token(apiClient).get("/user/" + user_slug + "/payouts");
  },
  getPayout(user_slug, payout_slug) {
    return refresh_token(apiClient).get("/user/" + user_slug + "/payouts/" + payout_slug);
  },
  deletePayout(user_slug, payout_slug) {
    return refresh_token(apiClient).delete("/user/" + user_slug + "/payouts/" + payout_slug);
  },
  cancelPayout(user_slug, payout_slug) {
    return refresh_token(apiClient).post("/user/" + user_slug + "/payouts/" + payout_slug + "/cancel_unclaimed");
  },

  getAdminRepeatedClaims(user_slug, params) {
    return refresh_token(apiClient).get("/admin/" + user_slug + "/repeated_claims", { params: params });
  }

}

function refresh_token(apiClient) {
  let auth_token = `Bearer ${localStorage.getItem('accessToken')}`
  apiClient.defaults.headers.common['Authorization'] = auth_token;
  return apiClient
}

