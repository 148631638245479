<template>
  <div>
    <nav class="level">
      <p class="level-item has-text-centered">
        <Brand
          type="image"
          width="150px"
          @click="go('/')"
        />
      </p>
    </nav>
    <div class="section">
      <slot />
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand.vue";

export default {
  components: {
    Brand
  },
  methods: {
    go(path) {
      this.$router.push(path);
    }
  }
}
</script>
