import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/deals/:filter/:location/:distance',
    name: 'deals',
    component: () => import('../views/Deals.vue'),
    props: true
  },
  {
    path: '/deal/:orgSlug/:slug/l/:locationId',
    name: 'deal',
    component: () => import('../views/Deal.vue'),
    props: true
  },
  {
    path: '/deal/:orgSlug/:slug/l/:locationId/print-qr',
    name: 'print-qr',
    meta: {
      layout: "blank",
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/PrintQr.vue'),
    props: true
  },
  {
    path: '/deal/:orgSlug/:slug/l/:locationId/edit/',
    name: 'deal-edit',
    meta: {
      layout: "blank",
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/DealEdit.vue'),
    props: true
  },
  {
    path: '/deal/:dealCode',
    name: 'scan-external',
    component: () => import('../views/ScanExternal.vue'),
    props: true
  },
  {
    path: '/deal/:orgSlug/:slug/l/:locationId/scan',
    name: 'scan',
    meta: {
      layout: "blank",
      requiresAuth: true
    },
    component: () => import('../views/Scan.vue'),
    props: true
  },
  {
      path: '/deal/:orgSlug/:slug/l/:locationId/scan-native',
      name: 'scan-native',
      meta: {
        layout: "blank",
        requiresAuth: true
      },
      component: () => import('../views/ScanNative.vue'),
      props: true
  },
  {
    path: '/deal/:orgSlug/:slug/l/:locationId/scan-success',
    name: 'scan',
    meta: {
      layout: "blank",
      requiresAuth: true
    },
    component: () => import('../views/ScanSuccess.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: "onboard"},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { layout: 'onboard'},
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { layout: "onboard"},
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    meta: { layout: "onboard"},
    component: () => import('../views/Subscribe.vue')
  },
  {
    path: '/password-forgot',
    name: 'password-forgot',
    meta: { layout: "onboard"},
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    path: '/password-reset/:token',
    name: 'password-reset',
    meta: { layout: "onboard"},
    component: () => import('../views/PasswordReset.vue'),
    props: true
  },
  {
    path: '/confirm-email/:token',
    name: 'confirm-email',
    meta: { layout: "onboard"},
    component: () => import('../views/ConfirmEmail.vue'),
    props: true
  },
  {
    path: '/get-started',
    name: 'getStarted',
    meta: { layout: "onboard"},
    component: () => import('../views/GetStarted.vue')
  },
  {
    path: '/organization-intro',
    name: 'organizationIntro',
    meta: { layout: "onboard"},
    component: () => import('../views/OrganizationIntro.vue')
  },
  {
    path: '/o/organization-new',
    name: 'organizationNew',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationNew.vue'),
    props: true
  },
  {
    path: '/o/organization-edit',
    name: 'organizationEdit',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationEdit.vue'),
    props: true
  },
  {
    path: '/o/organization-locations',
    name: 'organizationLocations',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationLocations.vue'),
    props: true
  },
  {
    path: '/o/organization-contact',
    name: 'organizationContact',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationContact.vue'),
    props: true
  },
  {
    path: '/o/organization-image',
    name: 'organizationImage',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationImage.vue'),
    props: true
  },
  {
    path: '/o/organization-deal-category',
    name: 'organizationDealCategory',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationDealCategory.vue'),
    props: true
  },
  {
    path: '/o/organization-deal-spend',
    name: 'organizationDealSpend',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationDealSpend.vue'),
    props: true
  },
  {
    path: '/o/organization-deal-description',
    name: 'organizationDealDescription',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationDealDescription.vue'),
    props: true
  },
  {
    path: '/o/organization-deal-disclaimers',
    name: 'organizationDealDisclaimers',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationDealDisclaimers.vue'),
    props: true
  },
  /*
  {
    path: '/o/organization-award-method',
    name: 'organizationAwardMethod',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationAwardMethod.vue'),
    props: true
  },
  */
  {
    path: '/o/organization-fund',
    name: 'organizationFund',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationFund.vue'),
    props: true
  },
  {
    path: '/o/organization-payment-method',
    name: 'organizationPaymentMethod',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationPaymentMethod.vue'),
    props: true
  },
  {
    path: '/o/organization-thank-you',
    name: 'organizationThankYou',
    meta: {
      layout: "onboard",
      requiresAuth: true
    },
    component: () => import('../views/onboard/OrganizationThankYou.vue'),
    props: true
  },
  {
    path: '/payouts',
    name: 'payout',
    meta: { requiresAuth: true },
    component: () => import('../views/Payout.vue'),
    props: true
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { requiresAuth: true },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/transactions',
    name: 'transactions',
    meta: { requiresAuth: true },
    component: () => import('../views/Transactions.vue')
  },
  {
    path: '/donations',
    name: 'dontations',
    meta: { requiresAuth: true },
    component: () => import('../views/Donation.vue')
  },
  {
    path: '/settings-general',
    name: 'settings-general',
    meta: {
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/SettingsGeneral.vue')
  },
  {
    path: '/settings-users',
    name: 'settings-users',
    meta: {
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/SettingsUsers.vue')
  },
  {
    path: '/settings-billing',
    name: 'settings-billing',
    meta: {
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/SettingsBilling.vue')
  },
  {
    path: '/organization-report',
    name: 'organization-report',
    meta: {
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/OrganizationReport.vue')
  },
  {
    path: '/organization-transactions/:status',
    name: 'organization-transactions',
    meta: {
      requiresAuth: true,
      requiresBusinessOwner: true
    },
    component: () => import('../views/OrganizationTransactions.vue'),
    props: true
  },
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminDashboard.vue')
  },
  {
    path: '/admin-reports',
    name: 'admin-reports',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminReports.vue')
  },
  {
    path: '/admin-businesses',
    name: 'admin-businesses',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminBusinesses.vue')
  },
  {
    path: '/admin-charities',
    name: 'admin-charities',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminCharities.vue')
  },
  {
    path: '/admin-users',
    name: 'admin-users',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminUsers.vue')
  },
  {
    path: '/admin-payouts',
    name: 'admin-payouts',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminPayouts.vue')
  },
  {
    path: '/admin-transactions',
    name: 'admin-transactions',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminTransactions.vue')
  },
  {
    path: '/admin-markets',
    name: 'admin-markets',
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
    component: () => import('../views/AdminMarkets.vue')
  },
  {
    path: '/network-error',
    name: 'network-error',
    component: () => import('../views/NetworkError.vue'),
  },
  { 
    path: '/',
    name: 'deals',
    component: () => import('../views/Deals.vue'),
  },
  { 
    path: '*',
    component: () => import('../views/NotFound.vue'), 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next('/login')
    } else {
      if (to.matched.some(record => record.meta.requiresAdmin) && !store.getters.isAdmin) {
        next('/')
      }
      if (to.matched.some(record => record.meta.requiresBusinessOwner)
        && !store.getters.hasBusinessAccess
        && !store.getters.isAdmin
      ) {
        next('/')
      }
    }
  }
  next()
})

export default router
