class NetworkError extends Error {
  constructor(...args) {
    super(...args)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError)
    }

    this.name = "NetworkError"
  }
}

export default NetworkError
