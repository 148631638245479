<template>
  <div
    :class="notificationClass"
  >
    <button
      v-if="dismissable"
      class="delete"
      @click="dismiss"
    ></button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "info"
    },
    dismissable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    notificationClass() {
      return `notification is-${this.type}`
    }
  },
  methods: {
    dismiss() {
      this.$emit("dismiss")
    }
  }
}
</script>
