import {NetworkError} from "@/errors"
import api from "@/service/api.js"

export const charity = {
  state: {
    
  },
  mutations: {
    
  },
  actions: {
    getCharities({commit}) {
      return api.getCharities()
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getCharity({commit}, params) {
      const slug = params.slug
      return api.getCharity(slug)
        .then(resp => {
          const charity = resp.data
          console.log(commit)
          //commit("setCharity", charity)
          return charity
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    donateToCharity({commit}, params) {
      return api.donateCharity(params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    createCharity({commit}, params) {
      return api.createCharity(params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    updateCharity({commit}, params) {
      return api.updateCharity(params.slug, params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    }
  },
  getters: {
    
  }
}
