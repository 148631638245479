class AuthError extends Error {
  constructor(...args) {
    super(...args)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError)
    }

    this.name = "AuthError"
  }
}

export default AuthError
