import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import socket from "@/service/socket.js";
import {AuthError, NetworkError} from "@/errors";
import Default from "./layouts/Default.vue";
import Blank from "./layouts/Blank.vue";
import Onboard from "./layouts/Onboard.vue";

// Add support for NativeScript
import {setupNativeScript} from "./service/nativescript";
// Setup NativeScript (will be a NOP, if not in a NativeScript shell)
setupNativeScript(router, store);

import currency from 'currency.js';
Vue.mixin({
  methods: {
    USD: function (cents) {
      return currency(cents, {fromCents: true, precision: 2})
    }
  }
});

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate)

import VueMask from 'v-mask';
Vue.use(VueMask);

import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
Vue.use(Autocomplete)

import Buefy from 'buefy'
import './assets/main.scss'
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.component('default-layout', Default);
Vue.component('blank-layout', Blank);
Vue.component('onboard-layout', Onboard);

Vue.config.productionTip = false

const uncaughtError = (err) => {
  if (window.NativeScript) {
    window.NativeScript.log(err instanceof AuthError ? "Auth Error" : err instanceof NetworkError ? "Network Error" : "Error", err.toString() );
  }
  if (err instanceof AuthError) {
    router.push('/logout?r=security')
  } else if (err instanceof NetworkError) {
    router.push('/network-error')
  } else {
    console.error(err)
  }
}
Vue.config.errorHandler = function (err, vm, info) {
  console.log(vm, info)
  uncaughtError(err)
}
window.addEventListener("unhandledrejection", function(promiseRejectionEvent) {
  console.log(promiseRejectionEvent)
  uncaughtError(promiseRejectionEvent.reason)
})

socket.userSocketConnect()
socket.businessSocketConnect()

new Vue({
  router,
  store,
  socket,
  render: h => h(App)
}).$mount('#app')
