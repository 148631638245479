<template>
  <img
    v-if="type == 'image'"
    v-on="$listeners"
    :src="imgSrc"
    :width="width"
    alt="Local Loot®"
    />
  <span
    v-else
    v-on="$listeners">
    Local Loot<sup>&reg;</sup>
  </span>
</template>

<script>
export default {
  props: ["type", "width"],
  computed: {
    imgSrc: function() {
      return require('../assets/logo.svg')
    }
  }
};
</script>

<style>
  
</style>
