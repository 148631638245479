import {NetworkError} from "@/errors";
import api from "@/service/api.js";

export const business = {
  actions: {
    getBusinessTransactions({commit}, params) { 
      const user_slug = params.user_slug
      const org_slug = params.org_slug
      const status = params.status
          
      return api.getBusinessTransactions(user_slug, org_slug, status)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.error)
            throw new Error(error.response.data.error)
          } else if (error.request) {
            console.error(error.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(error)
            throw error
          }
        })
    }, 
    updateBusinessTransactionStatus({commit}, params) { 
      const user_slug = params.user_slug
      const org_slug = params.org_slug
      const id = params.id
      const transaction_params = {
        status: params.status
      } 
          
      return api.updateBusinessTransactionStatus(user_slug, org_slug, id, transaction_params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    removeCreditCard({commit}, params) {
      const user_slug = params.user_slug
      const org_slug = params.org_slug
      return api.removeCreditCard(user_slug, org_slug)
        .then(resp => {
          const currentBusiness = resp && resp.data
          commit("setCurrentBusiness", currentBusiness)
          return currentBusiness
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    addTeamMember({commit}, params) {
      return api.addUserToOrganization(params.org_slug, params.user_slug, params.role)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.message)
            throw new Error(err.response.data.message)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    removeTeamMember({commit}, orgUserid) {
      return api.removeUserFromOrganization(orgUserid)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.message)
            throw new Error(err.response.data.message)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    updateTeamMemberRole({commit}, params) {
      return api.updateOrganizationUserRole(params.id, params.role)
        .then(resp => {
          console.log(commit)
          return resp
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.message)
            throw new Error(err.response.data.message)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    }
  },
}
