<template>
  <div class="container">
    <div>
      <nav class="container navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <a class="navbar-item" href="https://localloot.app">
            <Brand
              type="image"
              width="120px"
            />
          </a>

          <a
            v-if="loggedIn"
            class="navbar-item is-hidden-desktop">
            {{ firstName }} ({{ userBalance }})
          </a>


          <a
            role="button"
            :class="burgerClass"
            aria-label="menu"
            aria-expanded="false"
            @click="toggleNav"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          :class="navbarClass"
        >
          <div class="navbar-start">
            <a
              @click="go('/')"
              class="navbar-item">
              Deals
            </a>
            <a
              v-if="loggedIn"
              class="navbar-item"
              @click="go('/donations')"
            >
              Charities
            </a>
            <a
              href="https://localloot.app"
              class="navbar-item">
              About
            </a>
          </div>

          <div class="navbar-end">
            <a
              v-if="(hasBusiness || isMasqueradingAsBusiness) && currentBusiness.pendingAwards > 0"
              @click="go('/transactions/pending')"
              class="navbar-item">
              <span class="tag is-primary">
                {{ currentBusiness.pendingAwards }} pending awards
              </span>
            </a>

            <div
              v-if="loggedIn"
              :class="dropdownClass"
            >
              <a
                @click="dropdownClassToggle"
                class="navbar-link">
                {{ currentUser.name }} ({{ userBalance }})
              </a>

              <div
               class="navbar-dropdown">
                <a
                  @click="go('/profile')"
                  class="navbar-item">
                  edit profile
                </a>
                <a
                  @click="go('/deals/claimed/my-location/100')"
                  class="navbar-item">
                  claimed deals
                </a>
                <a 
                  @click="go('/payouts')"
                  class="navbar-item">
                  manage payouts
                </a>
                <a
                  v-if="currentUser.balance > 0"
                  @click="go('/donations')"
                  class="navbar-item"
                >
                  make a donation
                </a>

                <template v-if="hasBusiness">
                  <hr class="navbar-divider">
                  <div
                    v-for="b in currentUser.organization_access"
                    :key="b.slug"
                  >
                    <b class="pl-4">{{ b.name}}</b>
                    <a
                      @click="setCurrentBusiness(b.slug); go('/organization-report')"
                      class="navbar-item"
                    >
                      report
                    </a>
                    <a
                      @click="setCurrentBusiness(b.slug); go('/deals/user/my-location/100')"
                      class="navbar-item"
                    >
                      deals
                    </a>
                    <a
                      v-if="b.is_admin"
                      @click="setCurrentBusiness(b.slug); go('/settings-general')"
                      class="navbar-item"
                    >
                      settings
                    </a>
                  </div>
                </template>

                <template v-if="isMasqueradingAsBusiness">
                  <hr class="navbar-divider">
                  <div>
                    <b class="pl-4 has-text-danger">{{ currentBusiness.name }}</b>
                    <a
                      @click="go('/organization-report')"
                      class="navbar-item"
                    >
                      report
                    </a>
                    <a
                      @click="go('/deals/user/my-location/100')"
                      class="navbar-item">
                      deals
                    </a>
                    <a
                      @click="go('/settings-general')"
                      class="navbar-item"
                    >
                      settings
                    </a>
                  </div>
                </template>

                <a
                  v-if="!hasBusiness && !isMasqueradingAsBusiness"
                  @click="go('/o/organization-new')"
                  class="navbar-item">
                  create a deal
                </a>

                <hr
                  v-if="isAdmin"
                  class="navbar-divider">
                <a
                  v-if="isAdmin"
                  @click="go('/admin-dashboard')"
                  class="navbar-item has-text-danger"
                >
                  admin
                </a>
                <hr class="navbar-divider">
                <a
                  @click="go('/logout')"
                  class="navbar-item">
                  logout
                </a>
              </div>
            </div>
            <div
              v-else
              class="navbar-item">
              <div class="buttons">
                <a 
                  @click="go('/login')"
                  class="button is-small is-light"
                >
                  Log in
                </a>
                <a
                   @click="go('/get-started')"
                  class="button is-small is-info">
                  <strong>Sign up</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <Notification
      v-if="isMasqueradingAsBusiness"
      type="danger"
      @click.native="go('/admin-businesses')"
    >
      You are masquerading as <em>{{ currentBusiness.name }}</em>.
    </Notification>
    <Notification
      v-if="continueOnboarding"
      type="primary"
      @click.native="go('/o/organization-edit')"
    >
      Welcome back! Click here to continue your initial setup of <strong>{{ currentBusiness.name }}</strong>.
    </Notification>
    <Notification
      v-if="isMissingPayoutInfo"
      type="warning"
      @click.native="go('/payouts')"
    >
      You have earned {{ userBalance }}, but haven't entered any payout information yet!
    </Notification>

    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand.vue";
import Notification from "@/components/Notification.vue";

export default {
  components: {
    Brand,
    Notification
  },
  data () {
    return {
      items: [
        { title: 'Dashboard', path: '/dashboard', icon: 'bookmark' },
        { title: 'Settings', path: '/settings', icon: 'settings_applications' },
        { title: 'Logout', path: '/', icon: 'power_settings_new' },
      ],
      dropdownClass: 'navbar-item has-dropdown',
      right: null,
      burgerClass: 'navbar-burger burger',
      navbarClass: 'navbar-menu',
    }
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    hasBusiness: function() {
      return this.$store.getters.hasBusinessAccess;
    },
    isOwnBusiness: function() {
      return this.currentUser.organization_access.some(b => b.slug === this.currentBusiness.slug);
    },
    isMasqueradingAsBusiness: function() {
      return (this.currentBusiness && this.currentBusiness.slug && this.isAdmin && !this.isOwnBusiness);
    },
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;
    },
    continueOnboarding: function() {
      return (this.currentBusiness && this.currentBusiness.is_onboarded == false) ? true : false
    },
    firstName: function () {
      if (!this.currentUser.name) return '';
      return this.currentUser.name.toString().split(" ", 1)[0];
    },
    userBalance: function () {
      return this.USD(this.currentUser.balance).format().split(".", 1)[0];
    },
    isMissingPayoutInfo: function() {
      return (this.loggedIn && this.currentUser.balance > 0 && !this.currentUser.payout_email)
    }
  },
  methods: {
    go(path) {
      this.closeNav()
      this.$router.push(path)
    },
    setCurrentBusiness (org_slug) {
      // console.log('org_slug', org_slug)
      // console.log('this.currentUser', this.currentUser)
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: org_slug
      }
      this.$store.dispatch("setCurrentBusiness", params)
    },
    dropdownClassToggle() {
      if (this.dropdownClass == 'navbar-item has-dropdown') {
        this.dropdownClass = 'navbar-item has-dropdown is-active'
      } else {
        this.dropdownClass = 'navbar-item has-dropdown'
      }
    },
    closeNav() {
      this.dropdownClass = 'navbar-item has-dropdown'
      this.navbarClass = 'navbar-menu'
      this.burgerClass = 'navbar-burger burger'
    },
    toggleNav() {
      if (this.navbarClass == 'navbar-menu') {
        this.navbarClass = 'navbar-menu is-active'
        this.burgerClass = 'navbar-burger burger is-active'
      } else {
        this.navbarClass = 'navbar-menu'
        this.burgerClass = 'navbar-burger burger'
      }
    }
  }
}
</script>

<style>
.bg-white {
  background-color: white !important;
}
</style>
