import {AuthError} from "@/errors/index.js";
import api from "@/service/api.js";
import socket from "@/service/socket.js";
import {NetworkError} from "@/errors/index.js";
//import jwt_decode from 'jwt-decode';

export const auth = {
  state: {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    currentUser: JSON.parse(localStorage.getItem("currentUser")) || {},
    currentBusiness: JSON.parse(localStorage.getItem("currentBusiness")) || {}
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem("accessToken", accessToken);
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem("refreshToken", refreshToken);
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      if (window.NativeScript) {
        if (currentUser != null && typeof currentUser.slug !== 'undefined') {
            window.NativeScript.setLoginState(true);
        } else {
            window.NativeScript.setLoginState(false);
        }
      }
    },
    setCurrentBusiness(state, currentBusiness) {
      state.currentBusiness = currentBusiness;
      localStorage.setItem("currentBusiness", JSON.stringify(currentBusiness));
    },
  },
  actions: {
    login({commit}, params) {
      return api.login(params)
        .then(resp => {
          const accessToken = resp && resp.data && resp.data.accessToken
          commit("setAccessToken", accessToken)

          const refreshToken = resp && resp.data && resp.data.refreshToken
          commit("setRefreshToken", refreshToken)

          const user = resp && resp.data && resp.data.user
          commit("setCurrentUser", user)
          socket.userSocketConnect()

          // fetch & set the current business if only have access to one business
          if (user && user.organization_access && user.organization_access.length === 1) {
            const businessParams = {
              user_slug: user.slug,
              org_slug: user.organization_access[0].slug
            }
            api.getBusiness(businessParams)
              .then(businessResp => {
                const business = businessResp && businessResp.data
                if (typeof business !== 'undefined') {
                  commit("setCurrentBusiness", business)
                  socket.businessSocketConnect()
                  return resp
                } else {
                  throw new Error('Error fetching business data')
                }
              })
              .catch(err => {
                if (err.response) {
                  console.log(err.response)
                  throw err.response.data.message
                } else if (err.request) {
                  console.error(err.request)
                  throw new NetworkError('An unknown network error occurred')
                } else {
                  console.error(err)
                  throw err
                }
              })
          } else {
            return resp
          }
        })
        .catch(err => {
          if (err.response) {
            localStorage.clear()
            throw err.response.data.message
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred.')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    setCurrentBusiness({commit}, params) {
      return api.getBusiness(params)
        .then(resp => {
          const currentBusiness = resp && resp.data
          commit("setCurrentBusiness", currentBusiness)
          socket.businessSocketReconnect(0)
          return resp
        })
        .catch(err => {
          if (err.response) {
            throw err.response.data.error
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred.')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    resetPasswordAndlogin({commit}, params) {
      return api.resetPassword(params)
        .then(resp => {
          const token = resp && resp.data && resp.data.token;
          commit("setAccessToken", token);

          const user = resp && resp.data && resp.data.user;
          commit("setCurrentUser", user);
          socket.userSocketConnect()

          // fetch & set the current business if only have access to one business
          if (user && user.organization_access && user.organization_access.length === 1) {
            const businessParams = {
              user_slug: user.slug,
              org_slug: user.organization_access[0].slug
            }
            api.getBusiness(businessParams)
              .then(businessResp => {
                const business = businessResp && businessResp.data
                if (typeof business !== 'undefined') {
                  commit("setCurrentBusiness", business)
                  socket.businessSocketConnect()
                  return resp
                } else {
                  throw new Error('Error fetching business data')
                }
              })
              .catch(err => {
                if (err.response) {
                  console.log(err.response)
                  throw err.response.data.message
                } else if (err.request) {
                  console.error(err.request)
                  throw new NetworkError('An unknown network error occurred')
                } else {
                  console.error(err)
                  throw err
                }
              })
          } else {
            return resp
          }
        })
        .catch(err => {
          if (err.response) {
            localStorage.clear()
            throw err.response.data.message
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred.')
          } else {
            console.error(err)
            throw err
          }
        });
    },
    signup({commit}, params) {
      return api.signup(params)
        .then(resp => {
          const accessToken = resp && resp.data && resp.data.accessToken
          const refreshToken = resp && resp.data && resp.data.refreshToken
          const user = resp && resp.data && resp.data.user
          commit("setAccessToken", accessToken)
          commit("setRefreshToken", refreshToken)
          commit("setCurrentUser", user)
          socket.userSocketConnect()

          return resp
        })
        .catch(err => {
          if (err.response) {
            localStorage.clear()
            throw err.response.data.message
          } else if (err.request) {
            console.log(err.request)
            throw new NetworkError('An unknown network error occurred.')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        socket.userSocketDisconnect()
        socket.businessSocketDisconnect()
        const token = localStorage.getItem("accessToken");
        api.logout({token: token})
          .then(resp => {
            console.log(resp)
            commit("setAccessToken", "");
            localStorage.removeItem("accessToken");
            commit("setRefreshToken", "");
            localStorage.removeItem("refreshToken");
            commit("setCurrentUser", {});
            localStorage.removeItem("currentUser");
            commit("setCurrentBusiness", {});
            localStorage.removeItem("currentBusiness");
            localStorage.removeItem("currentBusinessAddress");
            localStorage.removeItem("currentDeal");
            resolve()
          })
          .catch(err => {
            if (err.response) {
              console.log(err.response.data.error)
              reject(err.response.data.error)
            } else if (err.request) {
              console.error(err.request)
              reject(new NetworkError('An unknown network error occurred.'))
            } else if (err instanceof AuthError) {
              commit("setAccessToken", "")
              localStorage.removeItem("accessToken")
              commit("setRefreshToken", "");
              localStorage.removeItem("refreshToken")
              commit("setCurrentUser", {});
              localStorage.removeItem("currentUser")
              commit("setCurrentBusiness", {})
              localStorage.removeItem("currentBusiness")
              localStorage.removeItem("currentBusinessAddress")
              localStorage.removeItem("currentDeal")
              resolve()
            } else {
              console.error(err)
              reject(err)
            }
          })
        })
    },
    /*
    async getRefreshToken({commit, state}, token) {
      if (state.refreshToken != token) {
        const refreshToken = api.getToken(token)
        commit("setRefreshToken", refreshToken);
      }
    },
    */

    updateCurrentUser({commit}, params) {
      return api.updateUser(params.slug, {user: params.user})
        .then(resp => {
          const user = resp && resp.data;
          const updateCurrentUser = user
          commit("setCurrentUser", updateCurrentUser);
          return resp
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
         } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    deleteCurrentUser({commit}, params) {
      return api.deleteUser(params.slug)
        .then(resp => {
          console.log(resp)
          commit("setAccessToken", "");
          localStorage.removeItem("accessToken");
          commit("setRefreshToken", "");
          localStorage.removeItem("refreshToken");
          commit("setCurrentUser", {});
          localStorage.removeItem("currentUser");
          commit("setCurrentBusiness", {});
          localStorage.removeItem("currentBusiness");
          localStorage.removeItem("currentBusinessAddress");
          localStorage.removeItem("currentDeal");
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },

    findUserByEmail({commit}, email) {
      return api.findUserByEmail(email)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.message)
            throw new Error(err.response.data.message)
         } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },

    getTransactions({commit}, slug) {
      return api.getTransactions(slug)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },

    createBusiness({commit}, params) {
      const user_slug = params.user_slug
      const organization = {organization: params.organization}

      return api.createBusiness(user_slug, organization)
        .then(resp => {
          const currentBusiness = resp && resp.data;
          commit("setCurrentBusiness", currentBusiness);
          socket.businessSocketConnect()
          return resp;
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    updateBusiness({commit}, params) {
      const user_slug = params.user_slug
      const slug = params.slug
      const organization = {organization: params.organization}

      return api.updateBusiness(user_slug, slug, organization)
        .then(resp => {
          const currentBusiness = resp && resp.data;
          commit("setCurrentBusiness", currentBusiness);
          return resp;
        })
        .catch(err => {
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              console.log(err.response.data.message)
              throw new Error("Unable to update business: " + err.response.data.message)
            } else {
              throw new Error("An unknown error occurred while attempting to update business!")
            }
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    /*
    purchaseCredits({commit}, params) {
      const user_slug = params.user_slug
      const slug = params.slug
      const organization = {organization: params.organization}

      return api.updateBusiness(user_slug, slug, organization)
        .then(resp => {
          const currentBusiness = resp && resp.data;
          commit("setCurrentBusiness", currentBusiness);
        })
        .catch(err => {
          console.log(err.response.data.error)
        })
    },
    */
  },
  getters: {
    accessToken: state => state.accessToken,
    currentUser: state => state.currentUser,
    currentBusiness: state => state.currentBusiness,
    isLoggedIn: state => !!state.accessToken,
    isAdmin: state => {
      return (!!state.accessToken
        && typeof state.currentUser !== 'undefined'
        && typeof state.currentUser.slug !== 'undefined'
        && typeof state.currentUser.is_admin !== 'undefined'
        && state.currentUser.is_admin
      ) ? true : false
    },
    hasBusinessAccess: state => {
      return (!!state.accessToken
        && typeof state.currentUser !== 'undefined'
        && typeof state.currentUser.slug !== 'undefined'
        && typeof state.currentUser.organization_access !== 'undefined'
        && state.currentUser.organization_access.length > 0
      ) ? true : false

    },
    canEdit: (state, getters) => {
      if ( typeof state.currentUser !== 'undefined'
        && typeof state.currentBusiness !== 'undefined'
      ) {
        return (getters.isAdmin) ? true : canClientUserPermissionEdit(state.currentUser, state.currentBusiness)
      }
      return false
    }
  }
}

function canClientUserPermissionEdit (currentUser, currentBusiness) {
  if (typeof currentUser !== 'undefined'
    && typeof currentBusiness !== 'undefined'
    && typeof currentBusiness.slug !== 'undefined'
    && typeof currentUser.organization_access !== 'undefined'
  ) {
    const access = currentUser.organization_access.find(org => org.slug === currentBusiness.slug)
    return (typeof access !== 'undefined' && (access.is_owner || access.is_admin)) ? true : false
  }
  return false
}
