/**
 * This file bootstraps the interface for NativeScript, if the app is in a NativeScript container....
 * The window.nsWebViewBridge will be defined if the app is running inside a container which will then
 * allow the window.NativeScript (our interface) to be created and defined.
 */

export function setupNativeScript(router, store) {
    if (navigator.userAgent.indexOf("NativeScript") === -1 || window.NativeScript) { return; }

    const NativeScript = {
        Bridge: null,
        environment: navigator.userAgent.indexOf("LLPROD") > 0 ? "production" : "staging",
        messageId: 0,
        eventCallbacks: [],
        router: router,
        store: store,

        setLoginState: function (state) {
            this.sendMessage("login", {state, account: this.store.getters.currentUser}, null);
        },

        /**
         * Function to start a NativeScript scan
         * @returns {Promise<unknown>}
         */
        scan: function () {
            return new Promise(resolve => {
                this.sendMessage("scan", {}, {success: resolve});
            });
        },

        /**
         * Triggers opening map
         * @returns {Promise<unknown>}
         */
        map: function (location) {
            return new Promise(resolve => {
                this.sendMessage("map", location, {success: resolve});
            });
        },

        log: function(severity, message) {
            this.sendMessage("log", {severity, message}, null);
        },

        /**
         * Configures the communication bridge between NativeScript and the Browser
         */
        setupBridge: function () {

            // Are we already setup?
            if (NativeScript.Bridge !== null) { return; }

            // Has the bridge activated?
            if (!window.nsWebViewBridge) {
                setTimeout(NativeScript.setupBridge, 100);
                return;
            }

            // Assign the bridge....
            NativeScript.Bridge = window.nsWebViewBridge;

            /**
             * This is what is called for all results back from NativeScript; it will direct the answer to the
             * proper callback or promise that is awaiting its information...
             */
            NativeScript.Bridge.on("result", (data) => {
                let callback = null;
                //console.log(JSON.stringify(data));
                if (data.id > 0) {
                    for (let i = 0; i < NativeScript.eventCallbacks.length; i++) {
                        if (NativeScript.eventCallbacks[i].id === data.id) {
                            callback = NativeScript.eventCallbacks[i];
                            NativeScript.eventCallbacks.splice(i, 1);
                            break;
                        }
                    }
                }
                if (callback) {
                    if (data.error) {
                        if (callback.error) {
                            callback.error(new Error(data.error));
                        }
                    } else if (callback.success) {
                        callback.success(data.results);
                    }
                } else if (data.id > 0) {
                    // This should never happen, so in case it does we will log it out...
                    console.error("Result message: " + data.id)
                }
            });

            NativeScript.Bridge.on("navigate", (data) => {
                NativeScript.sendMessage("navigation", data);
                if (NativeScript.router == null) {
                    window.location = "http://127.0.0.1:8181/";
                } else {
                    NativeScript.router.push(data);
                }
            });

            /**
             * Let NativeScript know we are ready on this end
             */
            NativeScript.sendMessage("ready", {
                loggedIn: store.getters.isLoggedIn,
                account: store.getters.currentUser
            }, {
                success: () => {
                    console.log("Ready Acknowledged!");
                }
            });
        },

        geolocation: {
                    getCurrentPosition(success, error = null, options = {}) {
                        NativeScript.sendMessage("geolocation", options, {success, error});
                    },
                    watchPosition(successCallback, errorCallback/* , options */) {
                        // TODO: Do we need to Wire this in???
                        errorCallback("Not Supported");
                    }
        },


        /**
         * Used to send a message from the Browser to NativeScript
         * @param message
         * @param data
         * @param callbacks
         */
        sendMessage: function (message, data, callbacks) {
            if (callbacks) {
                NativeScript.messageId++;
                callbacks.id = NativeScript.messageId;
                NativeScript.eventCallbacks.push(callbacks);
            }
            if (NativeScript && NativeScript.Bridge) {
                if (callbacks) {
                    NativeScript.Bridge.emit("bridge", {message: message, params: data, id: NativeScript.messageId});
                } else {
                    // No response expected
                    NativeScript.Bridge.emit("bridge", {message: message, params: data, id: 0});
                }
            } else {
                setTimeout(() => {
                    NativeScript.sendMessage(message, data, callbacks);
                }, 250);
            }
        },

    };

    window.NativeScript = NativeScript;
    NativeScript.setupBridge();
}

export function isNativeScript() {
    return !!window.NativeScript;
}
