export default {
  
  get_env() {
    const host = window.location.host;
    if (host === 'localloot.app') {
      return 'production'
    } else if (host === "www.localloot.app") {
      return "production"; 
    } else if (host === "deals.localloot.app") {
      return "production"; 
    } else if (host === "staging.localloot.app") {
      return "staging"; 
    } else if (host === "localloot-ui-staging.onrender.com") {
      return "staging"; 
    } else if (navigator.userAgent.indexOf("NativeScript") > 0) {
      if (navigator.userAgent.indexOf("LLPROD") > 0) {
        return "production";
      }
      return "staging";
    } else {
      return 'development'
    }
  },

  get_https() {
    let env = this.get_env()
    if (env == "production") {
      return "https://localloot-api-production.onrender.com";
    } else if (env == "staging") {
      return "https://localloot-api-staging.onrender.com";
    } else {
      const matches = window.location.host.match(/^([^:]+)/)
      if (matches.length > 1) {
        return `https://${matches[1]}:4001/`;
      } else {
        return "https://localhost:4001/";
      }
    }
  },

  get_wss() {
    let env = this.get_env()
    if (env == "production") {
      return "wss://localloot-api-production.onrender.com/socket";
    } else if (env == "staging") {
      return "wss://localloot-api-staging.onrender.com/socket";
    } else {
      let matches = window.location.host.match(/^([^:]+)/)
      if (matches.length > 1) {
        return `wss://${matches[1]}:4001/socket`;
      } else {
        return "wss://localhost:4001/socket";
      }
    }
  }

}
