import {Socket} from "phoenix"
import store from "@/store"
import url from "@/service/url.js"

const wss = url.get_wss()

let userSocket, userChannel, businessSocket, businessChannel

export default {
  userSocketConnect() {
    if (userSocket || userChannel) {
      //console.log("user socket: already created!")
      return
    }

    const currentUser = store.getters.currentUser
    const accessToken = localStorage.getItem("accessToken") // we can't rely on the accessToken from the store because api.js's refreshAuthLogic can only update localStorage when the token is refreshed, not the store
    if (currentUser && currentUser.slug != undefined) {
      //console.log("user socket: creating")
      userSocket = new Socket(wss, {params: {token: accessToken}})
      //userSocket.onOpen(() => console.log("user socket: opened"))
      //userSocket.onClose(() => console.log("user socket: closed"))
      userSocket.onError((error) => {
        console.log("user socket: error!", error)
        this.userSocketReconnect(5000)
      })

      //console.log("user socket: connecting")
      userSocket.connect()

      userChannel = userSocket.channel("user:" + currentUser.slug)
      //userChannel.onError(() => console.log("user socket channel: error!"))
      //userChannel.onClose(() => console.log("user socket channel: closed"))
      userChannel.on("update_current_user", user => {
        //console.log("user socket channel: updating current user");
        if (user.slug == store.getters.currentUser.slug) {
          store.commit("setCurrentUser", user)
          //console.log("user socket channel: updated current user")
        } else {
          console.log("user socket channel: failed to update user (user mismatch)!")
        }
      })

      //console.log("user socket channel: joining")
      userChannel.join()
    } else {
      console.log("user socket: not created (not logged in)")
    }
  },
  userSocketDisconnect(callback) {
    if (!userSocket) {
      //console.log("user socket: not yet created!")
      if (typeof callback === "function") {
        callback()
      }
      return
    }

    if (userChannel) {
      //console.log("user socket channel: leaving")
      userChannel.leave()
      userChannel = null
    }// else {
    //  console.log("user socket channel: not joined!")
    //}
    
    //console.log("user socket: disconnecting")
    userSocket.disconnect(() => {
      //console.log("user socket: disconnected")
      userSocket = null

      if (typeof callback === "function") {
        callback()
      }
    })
  },
  userSocketReconnect(wait) {
    //console.log("user socket: reconnecting")
    this.userSocketDisconnect(() => {
      setTimeout(() => this.userSocketConnect(), wait)
    })
  },

  businessSocketConnect() {
    if (businessSocket || businessChannel) {
      console.log("business socket: already created!")
      return
    }

    const currentBusiness = store.getters.currentBusiness
    const accessToken = localStorage.getItem("accessToken") // we can't rely on the accessToken from the store because api.js's refreshAuthLogic can only update localStorage when the token is refreshed, not the store
    if (currentBusiness && currentBusiness.slug != undefined) {
      //console.log("business socket: creating")
      businessSocket = new Socket(wss, {params: {token: accessToken}});
      //businessSocket.onOpen(() => console.log("business socket: opened"))
      //businessSocket.onClose(() => console.log("business socket: closed"))
      businessSocket.onError((error) => {
        console.log("business socket: error!", error)
        this.businessSocketReconnect(5000)
      })

      //console.log("business socket: connecting")
      businessSocket.connect()

      businessChannel = businessSocket.channel("organization:" + currentBusiness.slug)
      //businessChannel.onError(() => console.log("business socket channel: error!"))
      //businessChannel.onClose(() => console.log("business socket channel: closed"))
      businessChannel.on("update_current_organization", business => {
        //console.log("business socket channel: updating current business")
        if (business.slug == store.getters.currentBusiness.slug) {
          store.commit("setCurrentBusiness", business);
          //console.log("business socket channel: updated current business")
        } else {
          console.log("business socket channel: failed to update business (organization mismatch!)")
        }
      })

      //console.log("business socket channel: joining")
      businessChannel.join()
    } else {
      console.log("business socket: not created (no business access)")
    }
  },
  businessSocketDisconnect(callback) {
    if (!businessSocket) {
      //console.log("business socket: not yet created!")
      if (typeof callback === "function") {
        callback()
      }
      return
    }

    if (businessChannel) {
      //console.log("business socket channel: leaving")
      businessChannel.leave()
      businessChannel = null
    }// else {
    //  console.log("business socket channel: not joined!")
    //}
    
    //console.log("business socket: disconnecting")
    businessSocket.disconnect(() => {
      //console.log("business socket: disconnected")
      businessSocket = null

      if (typeof callback === "function") {
        callback()
      }
    })
  },
  businessSocketReconnect(wait) {
    //console.log("business socket: reconnecting")
    this.businessSocketDisconnect(() => {
      setTimeout(() => this.businessSocketConnect(), wait)
    })
  },
}
