import {NetworkError} from "@/errors";
import api from "@/service/api.js";

export const deal = {
  state: {
    dealsFilter: 'all',
    dealsLocationFilter: 'my-location',
    dealsDistanceFilter: null,
    currentDeal: JSON.parse(localStorage.getItem("currentDeal")) || {},
    currentLocation: JSON.parse(localStorage.getItem("currentLocation")) || {},
    destinations: JSON.parse(localStorage.getItem("destinations")) || [],
  },
  mutations: {
    setDealsFilter(state, filter) {
      state.dealsFilter = filter
    },
    setDealsLocationFilter(state, location) {
      state.dealsLocationFilter = location
    },
    setDealsDistanceFilter(state, distance) {
      state.dealsDistanceFilter = distance
    },
    setDeal(state, currentDeal) {
      state.currentDeal = currentDeal;
      localStorage.setItem("currentDeal", JSON.stringify(currentDeal));
    },
    setLocation(state, currentLocation) {
      state.currentLocation = currentLocation;
      localStorage.setItem("currentLocation", JSON.stringify(currentLocation));
    },
    setDestinations(state, destinations) {
      state.destinations = destinations;
      localStorage.setItem("destinations", JSON.stringify(destinations));
    },
  },
  actions: {
    filterDeals({commit}, filter) {
      commit("setDealsFilter", filter)
    },
    filterDealsLocation({commit}, location) {
      commit("setDealsLocationFilter", location)
    },
    filterDealsDistance({commit}, distance) {
      commit("setDealsDistanceFilter", distance)
    },
    getDeals({commit}, path) {
      return api.getDeals(path)
        .then(resp => {
          console.log(commit)
          // "user" deals responses are an array of organizations, each with an array of deals
          if (path.match(/^user\//) && (resp.data.length > 0)) {
            const org = resp.data.find(org => org.slug == JSON.parse(localStorage.getItem("currentBusiness")).slug)
            return org.deals
          // all the other deals responses are just an array of deals
          } else {
            return resp.data
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getDestinations({commit}) {
      return api.getDestinations()
        .then(resp => {
          commit("setDestinations", resp.data);
          return resp.data;
        })
        .catch(err => {
          if (err.response) {
            console(err.response.data);
            throw new Error(err.response.data.error)
          } else if (err.request) {
            console.error(err.request)
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getDeal({commit}, slug) {
      return api.getDeal(slug)
        .then(resp => {
          commit("setDeal", resp.data);
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getDealByCode({commit}, dealCode) {
      return api.getDealByCode(dealCode)
        .then(resp => {
          commit("setDeal", resp.data)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    clickClaimDeal({commit}, params) {
      return api.clickClaimDeal(params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    scanClaimDeal({commit}, params) {
      return api.scanClaimDeal(params)
        .then(resp => {
          console.log(commit)
          return resp.data
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data.message)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    clearDeal({commit}) {
      commit("setDeal", {});
    },
    createDeal({commit}, params) {
      const user_slug = params.user_slug
      const org_slug = params.org_slug
      const deal = {deal: params.deal}

      return api.createDeal(user_slug, org_slug, deal)
        .then(resp => {
          const currentDeal = resp && resp.data;
          commit("setDeal", currentDeal);
          return currentDeal
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    updateDeal({commit}, params) {
      const user_slug = params.user_slug
      const org_slug = params.org_slug
      const slug = params.slug
      const deal = {deal: params.deal}

      return api.updateDeal(user_slug, org_slug, slug, deal)
        .then(resp => {
          const currentDeal = resp && resp.data;
          commit("setDeal", currentDeal);
          return currentDeal
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data)
            throw new Error(err.response.data)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getLocation({commit, state}) {
      return new Promise((resolve, reject) => {
        const options = {
          enableHighAccuracy: true,
          timeout: 10000
        };
        const gotCurrentPosition= pos => {
          const currentLocation = {"lat": pos.coords.latitude, "lng": pos.coords.longitude}
          if (state.currentLocation != currentLocation) {
            commit("setLocation", currentLocation)
            resolve(currentLocation);
          } else {
            resolve(state.currentLocation);
          }
        };

        if (window.NativeScript) {
          window.NativeScript.geolocation.getCurrentPosition(gotCurrentPosition, err => {
            console.log(err.message)
            reject(err.message);
          }, options);
        } else {
          if(!("geolocation" in navigator)) {
            reject(new Error('Geolocation is not available.'));
          }

          navigator.geolocation.getCurrentPosition(gotCurrentPosition, err => {
            console.log(err.message)
            reject(err.message);
          }, options);
        }
      });
    }
  },
  getters: {
    dealsFilter: state => state.dealsFilter,
    dealsLocationFilter: state => state.dealsLocationFilter,
    dealsDistanceFilter: state => state.dealsDistanceFilter,
    currentDeal: state => state.currentDeal,
    currentLocation: state => state.currentLocation,
    destinations: state => state.destinations
  },
}
