import {NetworkError} from "@/errors";
import api from "@/service/api.js";

export const market = {
  state: {
    currentMarket: JSON.parse(localStorage.getItem("currentMarket")) || {},
  },
  mutations: {
    setMarket(state, market) {
      state.currentMarket = market
      localStorage.setItem("currentMarket", JSON.stringify(market))
    }
  },
  actions: {
    getMarkets({commit}) {
      return api.getDmiMarkets()
        .then(resp => {
          console.log(commit)
          return resp
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    getMarket({commit}, params) {
      const dmi_id = params.dmi_id
      return api.getDmiMarketByDmiId(dmi_id)
        .then(resp => {
          const market = resp.data
          commit("setMarket", market)
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    },
    createMarket({commit}, params) {
      const user_slug = params.user_slug
      const dmi_market = params.dmi_market
      return api.createDmiMarket(user_slug, dmi_market)
        .then(resp => {
          console.log(commit)
          return resp
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data.error)
            throw new Error(err.response.data.error)
          } else if (err.request) {
            throw new NetworkError('An unknown network error occurred')
          } else {
            console.error(err)
            throw err
          }
        })
    }
  },
  getters: {
    currentMarket: state => state.currentMarket
  }
}
