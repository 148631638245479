export const onboard = {
  state: {
    currentBusinessAddress: localStorage.getItem("currentBusinessAddress") || ""
  },
  mutations: {
    setCurrentBusinessAddress(state, address) {
      state.currentBusinessAddress = address
      localStorage.setItem("currentBusinessAddress", address)
    },
    clearCurrentBusinessAddress(state) {
      state.currentBusinessAddress = null
      localStorage.removeItem("currentBusinessAddress");
    }
  },
  actions: {
    updateBusinessAddress({commit}, param) {
      commit("setCurrentBusinessAddress", param);
    },
    clearBusinessAddress({commit}) {
      commit("clearCurrentBusinessAddress");
    }
  },
  getters: {
    currentBusinessAddress: state => state.currentBusinessAddress,
  }
}
